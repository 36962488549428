import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import * as style from './overview-wpArticle.module.scss';
import parse from "html-react-parser";
import AToLink from '../components/AToLink/AToLink';
import { createId, nodeIsInternalLink } from '../scripts/helper';
import AdSense from 'react-adsense';
import Seo from '../components/SEO/SEO';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import AdSenseRerenderer from '../components/AdSenseRerenderer/AdSenseRerenderer';



export default class OverviewWpArticleTemplate extends React.Component {

  // state = {};
  pageType = this.props.pageContext.pageType;

  render() {

    const {
      pagesArr, mainPageSlug, mainPageTitle, mainPageContent, header_headline,
    } = this.props.pageContext;
   
    const articlePreviews = pagesArr.map((page, i) => {
      // debugger;
      const featuredImage = page._embedded['wp:featuredmedia'] ? page._embedded['wp:featuredmedia'][0] : null;

      return (
        <div key={i} className={style.ArticlePreview}>
          <div className={style.ImageWrapper} >
            <Link
              to={`/${mainPageSlug}/${page.slug}/`}
              className={style.ImageLink}
            >
              {featuredImage &&
                <img src={featuredImage.media_details.sizes.medium.source_url} alt={featuredImage.alt_text} />}
            </Link>
          </div>
          <h3>
            <Link to={`/${mainPageSlug}/${page.slug}/`} dangerouslySetInnerHTML={{ __html: page.title.rendered }} />
          </h3>
          <div className={style.Excerpt} dangerouslySetInnerHTML={{ __html: page.excerpt.rendered }} />
          <Link to={`/${mainPageSlug}/${page.slug}/`} > Zum Artikel </Link>
        </div>

      );
    });
    // add adsense as last element
    const adKey = `${mainPageTitle.replace(/\s/g, '_')}_${createId(10)}`
    articlePreviews.push(
      <ErrorBoundary key={articlePreviews.length}>
        <div key={mainPageTitle + '-adsense'} className={style.ArticlePreview}>
          <AdSenseRerenderer maxWidth={700}>
            <AdSense.Google className="adsbygoogle infeed"
              key={adKey}
              style={{ display: 'block' }}
              format="fluid"
              layoutKey="-5n+c3-2t-7j+10m"
              client="ca-pub-2126121658624128"
              slot="9361842981"
            />
          </AdSenseRerenderer>
        </div>
      </ErrorBoundary>
    );

    return (
      <>

        <Layout
          headlines={[header_headline]}
          className="centeredPage--TextPage articleOverviewPage"
          pageType={this.pageType}
        // params
        >
          <h1>{mainPageTitle}</h1>
          {parse(mainPageContent, {
            replace: (node) => {
              if (nodeIsInternalLink(node)) {
                // console.log('Ersetze a zu link', <AToLink key={index} node={node} />);
                return <AToLink node={node} />
              }

              if (node.type === 'tag' && node.attribs.class === 'rt-reading-time') {
                return null;
              }
              return undefined;
            }
          })}
          <div className={style.ArticlePreviews}>{articlePreviews}</div>

        </Layout>
      </>
    );
  }
}

export function Head(props) {
  const {
    yoast_meta,
  } = props.pageContext;
  // debugger;
  // const params = props.pageContext.params ? props.pageContext.params : {}

  let yoast_meta_title = null,
    yoast_meta_description = null;
  // check of index 4 is really title (in case it will be changed in the future)
  if (yoast_meta[4].property.indexOf('title') >= 0) {
    yoast_meta_title = yoast_meta[4].content
  } else {
    throw Error('Yoast title seems to be at another position in the array!')
  }
  // check of index 0 is really description 
  if (yoast_meta[0].name.indexOf('description') >= 0) {
    yoast_meta_description = yoast_meta[0].content
  } else {
    throw Error('Yoast description seems to be at another position in the array!')
  }
  return (
    <Seo
      title={yoast_meta_title}
      meta_description={yoast_meta_description}
    />

  )
}